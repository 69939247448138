import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ChipInput from "../../shared/components/ChipInput";

import { Box, Button, TextField, Alert } from "@mui/material";
import TextDnD from "../../shared/components/TextDnD/TextDnD.component";  

const NewStepOne = ({
  intent,
  validateNoDuplicateName,
  validateNoDuplicatePhrases,
  onClose,
  setStep,
  setNewIntent,
  agentSelected,
}) => {
  const [name, setName] = useState("");
  const [phrases, setPhrases] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorIndex, setErrorIndex] = useState([]);
  const [dirty, setDirty] = useState({});

  const { t } = useTranslation("intents");

  useEffect(() => {
    if (intent && Object.keys(intent).length !== 0) {
      setName(intent.name);
      setPhrases(intent.phrases);
      setAnswer(intent.response.text);
    }
  }, [intent]);

  useEffect(() => {
    const temp = {};
    const tempErrorIndex = [];

    if (!dirty.name && name !== "") {
      setDirty({ ...dirty, name: true });
    }
    if (!dirty.phrases && phrases.length > 0) {
      setDirty({ ...dirty, phrases: true });
    }
    let duplicatePhrases = phrases
      ? validateNoDuplicatePhrases(phrases)
      : false;
    temp.name = name ? "" : t("errorNameRequired");
    temp.name = validateNoDuplicateName(name)
      ? t("errorNameDuplicate")
      : temp.name;
    temp.phrases = phrases.length > 0 ? "" : t("errorphrasesRequired");
    temp.phrases =
      phrases.length > 0 && duplicatePhrases
        ? t("errorphrasesDuplicate", duplicatePhrases)
        : temp.phrases;

    answer.forEach((ans, index) => {
      if (ans.length > 10000) {
        tempErrorIndex[index] = t("intents:errorLengthValidation");
      } else {
        tempErrorIndex[index] = ""; 
      }
    });
  
    temp.answer = tempErrorIndex;
    setErrors({ ...temp });
    setErrorIndex(tempErrorIndex);
  }, [name, phrases, answer, t]);

  const nextStep = useCallback(() => {
    let newIntent = intent;
    newIntent.name = name;
    newIntent.phrases = phrases;
    newIntent.response = {
      text: answer,
    };
    setNewIntent(newIntent);
    setStep(1);
  }, [setStep, setNewIntent, name, phrases, answer]);

  const hasErrors = useMemo(() => {
    const generalErrors = Object.keys(errors).some((key) => key !== "answer" && errors[key] !== "");
    const answerErrors = errorIndex.some((err) => err !== "");
    return generalErrors || answerErrors;
  }, [errors, errorIndex]);
  

  return (
    <div>
      <Box p={2} sx={{ width: 600 }}>
        <TextField
          id="modal-name"
          label={t("name")}
          autoFocus
          value={name}
          variant="standard"
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          onChange={(e) => setName(e.target.value)}
          error={errors.name && dirty.name}
          helperText={errors.name && dirty.name ? errors.name : ""}
        />
        <ChipInput
          id="new-intent-name-phrases"
          name="phrases"
          source="phrases"
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          label={t("intents:phrases")}
          value={phrases}
          getValidation={{ get: true, agentSelected }}
          updateProps={async (e) => {
            setPhrases(e);
          }}
          error={
            errors.phrases !== "" &&
            errors.phrases !== undefined &&
            dirty.phrases
              ? errors.phrases
              : ""
          }
        />
        <div id="new-intent-name-answer">
          <div style={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}>
            <TextDnD
              title={t("intents:answer")}
              items={answer}
              errors={!!errors.answer}
              errorArray={errorIndex}
              setItems={(e) => {
                setAnswer(e);
              }}
            />
            <Alert variant="filled" severity="info"
              sx={{ backgroundColor: "rgba(75, 139, 251, 1)", color: "white" }}>
              {t("messageAlert")}
            </Alert>
          </div>
        </div>
      </Box>
      <Box
        p={2}
        sx={{ minWidth: 400, display: "flex", justifyContent: "flex-end" }}>
        <Button id="modal1-cancel-button" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          id="modal1-next-button"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={() => nextStep()}>
          {t("next")}
        </Button>
      </Box>
    </div>
  );
};

export default NewStepOne;
