import { React, useState, useEffect } from "react";
import { Box, Tab, Dialog, Tooltip, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EdgeModal from "./EdgeModal.component";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PropTypes from "prop-types"; // Importar PropTypes
import { cloneDeep, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import {
  setLabel,
  validateIntentTransition,
  validateConditionTransition,
  validateEntityTransition,
  validateMediaTransition,
  validateTimeCondition,
  validateDateCondition,
  validateDayCondition,
  validateHolidayCondition,
  validateScheduleCondition,
  transformHolidays,
} from "./ModalComponents/auxiliarEdgeModal"; // Importar setLabel

function a11yProps(index) {
  return {
    id: `edgeModal-tab-${index}`,
    "aria-controls": `edgeModal-tabpanel-${index}`,
  };
}

export default function EdgeModalTabsPanel({
  open,
  onClose,
  editEdgeProps,
  onSave,
  onDelete,
  intents,
  edges,
  pages,
  userPermissions,
  propsSelectedTab,
  onDeleteTab,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [transitions, setTransitions] = useState([]);
  const [editEdge, setEditEdge] = useState({ data: {} });
  const [editTransition, setEditTransition] = useState({});
  const [conflictTransitions, setConflictTransitions] = useState([]);
  const [previousSelectedTab, setPreviousSelectedTab] = useState();
  const [updateModal, setUpdateModal] = useState(false);
  const [previousType, setPreviousType] = useState(null);
  const [validTransitions, setValidTransitions] = useState([]);
  const [displayValidations, setDisplayValidations] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    handleEdgeUpdate();
  }, [editEdge, selectedTab]);

  useEffect(() => {
    if (Object.keys(editEdgeProps?.data).length === 0) {
      return;
    }
    let tempSelectedTab = propsSelectedTab || 0;
    setEditEdge(cloneDeep(editEdgeProps));
    setTransitions(editEdgeProps.data.transitions);
    setSelectedTab(tempSelectedTab);
    setConflictTransitions(
      Array(editEdgeProps.data.transitions.length).fill(true),
    );
    setValidTransitions(
      Array(editEdgeProps.data.transitions.length).fill(true),
    );
  }, [open]);

  useEffect(() => {
    if (open) {
      // If previousType is defined, validate transitions of the previous type
      if (previousType) {
        validateTransitions(previousType);
        setPreviousType(null);
      }
      validateTransitions(editTransition.type);
    }
  }, [editTransition]);

  const handleEdgeUpdate = () => {
    // If the edge is not defined, return
    if (!editEdge?.data && !editEdge?.data.transitions) {
      return;
    }
    // Update previous selected transition
    if (previousSelectedTab !== undefined && editEdge.data.transitions) {
      editEdge.data.transitions[previousSelectedTab] = editTransition;
      setPreviousSelectedTab(undefined);
    }

    // If the edge doesn't have the selectedTab transition, create a new one
    if (
      editEdge.data.transitions &&
      !editEdge.data?.transitions?.[selectedTab]
    ) {
      createNewTransition();
      setUpdateModal(!updateModal);
    }
    // If the edge has transitions, set the selected transition
    if (editEdge.data.transitions?.[selectedTab]) {
      setEditTransition(editEdge.data.transitions[selectedTab]);
      setUpdateModal(!updateModal);
    }
  };

  const createNewTransition = () => {
    // create condition data for new transition
    const baseConditionData = {
      is_new: true,
      label: "New transtion",
      message: "",
      oid: "",
      type: "intent",
      conditions: [],
      intents: [
        intents?.find((intent) => intent.name?.startsWith("default"))?.name || "",
      ],      
      entity: {},
      cleanParams: [],
      presets: [],
      sourceId: editEdge.source.id,
      targetId: editEdge.target.id,
      sourceName: editEdge.source.data.label,
      targetName: editEdge.target.data.label,
      labelX:
        editEdge.source.position.x +
        (editEdge.target.position.x - editEdge.source.position.x) / 2,
      labelY:
        editEdge.source.position.y +
        (editEdge.target.position.y - editEdge.source.position.y) / 2,
    };
    // create a new transition for the edge
    let tempEditEdge = { ...editEdge };
    tempEditEdge.data.transitions.push(baseConditionData);

    setEditEdge(tempEditEdge);
    setTransitions(tempEditEdge.data.transitions);
    setValidTransitions((prevValidTransitions) => [
      ...prevValidTransitions,
      true,
    ]);
  };

  const handleChange = (event, newselectedTab) => {
    setPreviousSelectedTab(selectedTab);
    setSelectedTab(newselectedTab);
    if (!displayValidations) setDisplayValidations(true);
  };

  const handleClose = () => {
    setEditEdge({ data: {} });
    setPreviousSelectedTab(undefined);
    setEditTransition({});
    setConflictTransitions([]);
    setValidTransitions([]);
    setTransitions([]);
    setDisplayValidations(false);
    onClose();
  };

  // Get the transition data in the format required by traces
  const getTransition = (editEdge, index) => {
    let condition = editEdge.data.transitions[index];
    let change = editEdge;
    return {
      animated: condition.animated,
      type: change.type,
      data: {
        ...condition,
      },
      id: index === 0 ? change.id : `${change.id} - ${index + 1}`,
      is_new: condition.is_new,
      is_modified: condition.is_modified,
      timestamp: new Date(),
      source: change.source,
      target: change.target,
    };
  };

  const handleDeleteTransition = (index) => {
    let tempEditTransition = getTransition(editEdge, index);

    if (editEdge.data.transitions.length !== 1) {
      let tempEditEdge = { ...editEdge };
      tempEditEdge.data.transitions.splice(index, 1);
      setConflictTransitions((prev) => {
        const newConflictTransitions = [...prev];
        newConflictTransitions.splice(index, 1);
        return newConflictTransitions;
      });
      setValidTransitions((prev) => {
        const newValidTransitions = [...prev];
        newValidTransitions.splice(index, 1);
        return newValidTransitions;
      });

      let newIndex =
        index === tempEditEdge.data.transitions.length ? index - 1 : index;

      setTransitions(tempEditEdge.data.transitions);
      setSelectedTab(newIndex);
      onDeleteTab(tempEditTransition, tempEditEdge);

      // If the index is different, update the edge manually
      if (newIndex === index) {
        handleEdgeUpdate();
      }
    } else {
      onDelete(tempEditTransition);
    }
  };

  const onSaveEdge = () => {
    let tempEditEdge = { ...editEdge };
    let tempEditTransition = { ...editTransition };

    // Validate if the edge has been modified
    if (
      !isEqual(editEdgeProps.data.transitions[selectedTab], tempEditTransition)
    ) {
      tempEditTransition.is_modified = true;
      tempEditTransition.timestamp = new Date();
    }

    // Set the transition label
    tempEditTransition = setLabel(tempEditTransition);

    tempEditEdge.data.transitions[selectedTab] = tempEditTransition;

    setEditEdge({ data: {} });
    setEditTransition({});

    onSave(tempEditEdge);
  };

  const getTabStyles = (index) => {
    const isConflict =
      conflictTransitions[index] !== undefined && !conflictTransitions[index];
    const isValid =
      validTransitions[index] !== undefined && !validTransitions[index];

    if (!displayValidations) {
      return;
    }

    return {
      color: isConflict || isValid ? "red" : "",
      borderBottom: isConflict || isValid ? "2px solid red" : "none",
      "&.Mui-selected": {
        color: isConflict || isValid ? "red" : "",
        borderBottom: isConflict || isValid ? "2px solid red" : "none",
      },
    };
  };

  const onTransitionUpdate = (
    transition,
    valid,
    previousType = null,
    dirty = true,
  ) => {
    setEditTransition(transition);

    // TODO -> Check if this is necessary
    editEdge.data.transitions[selectedTab] = transition;
    setTransitions(editEdge.data.transitions);
    // Before changing type, validate old type transitions
    setPreviousType(previousType);

    // Create a copy of the validTransitions array and update the specific element
    const updatedValidTransitions = [...validTransitions];
    updatedValidTransitions[selectedTab] = valid;

    if (dirty && !displayValidations) {
      setDisplayValidations(true);
    }

    // Update valid transitions
    setValidTransitions(updatedValidTransitions);
  };

  const getConflictTransitions = (type) => {
    let sameNodeTransitions = [];
    let differentNodeTransitions = [];

    edges.forEach((edge) => {
      const source = edge.source.id ? edge.source.id : edge.source;
      if (source === editEdge?.source.id) {
        edge.data.transitions.forEach((transition) => {
          const target = edge.target.id ? edge.target.id : edge.target;
          const isSameTarget = target === editEdge?.target.id;
          if (transition.type === type && !isSameTarget) {
            differentNodeTransitions.push(transition);
          }
        });
      }
    });

    editEdge.data.transitions.forEach((transition, index) => {
      if (transition.type === type) {
        if (index === selectedTab) {
          sameNodeTransitions.push({ ...editTransition, indexInEdge: index });
        } else {
          sameNodeTransitions.push({ ...transition, indexInEdge: index });
        }
      }
    });

    return { sameNodeTransitions, differentNodeTransitions };
  };

  const validateTransitions = (type) => {
    let { sameNodeTransitions, differentNodeTransitions } =
      getConflictTransitions(type);

    // create tmpValid object, replacing only transitions of the type (those in sameNodeConflictTransitions),
    // with correct index in the Edge, to then update the valid element correctly
    let tmpValid = {};
    sameNodeTransitions.forEach((t) => (tmpValid[t.indexInEdge] = true));

    if (sameNodeTransitions.length === 0) {
      tmpValid[selectedTab] = true;
    }

    for (let i = sameNodeTransitions.length - 1; i >= 0; i--) {
      let transition = sameNodeTransitions[i];

      // if already invalid, do not check the transition, but do not remove from array either
      // as it will be needed to check remaining transitions
      if (!tmpValid[transition.indexInEdge]) {
        continue;
      }

      // remove transition from the array, as we are already validating it
      sameNodeTransitions.splice(i, 1);

      // compare with transitions to same node. In this case, if not valid, SET BOTH as valid = false in the map
      sameNodeTransitions.forEach((other) => {
        if (!validateSameTransition(transition, other)) {
          tmpValid[transition.indexInEdge] = false;
          tmpValid[other.indexInEdge] = false;
        }
      });

      if (!tmpValid[transition.indexInEdge]) {
        // TODO: remove if storing array of conflicts
        continue;
      }

      if (
        differentNodeTransitions.some(
          (other) => !validateSameTransition(transition, other),
        )
      ) {
        tmpValid[transition.indexInEdge] = false;
      }
    }

    setConflictTransitions((prev) => {
      const newConflictTransitions = [...prev];
      Object.keys(tmpValid).forEach((key) => {
        newConflictTransitions[key] = tmpValid[key];
      });
      return newConflictTransitions;
    });
  };

  //Validates if there is an equal transition but with a different destination node
  const validateSameTransition = (transition, other) => {
    if (transition.type !== other.type) {
      return true;
    }

    switch (transition.type) {
      case "intent":
        return validateIntentTransition(transition, other);
      case "condition":
        return validateConditionTransition(transition, other);
      case "entity":
        return validateEntityTransition(transition, other);
      case "media":
        return validateMediaTransition(transition, other);
      case "date":
        return validateSameDate(transition, other);
      case "direct":
        return false;
      default:
        return true;
    }
  };

  //Validates if there is a date match
  const validateSameDate = (transition, other) => {
    let invalidConditions = [];
    let isHoliday = false;
    let tempChanges = transformHolidays(transition.conditions);
    let tempConditions = transformHolidays(other.conditions);

    for (const change of tempChanges) {
      for (const condition of tempConditions) {
        if (change.op === condition.op) {
          if (!validateCondition(change, condition, invalidConditions)) {
            isHoliday = change.op === "holiday";
          }
        }
      }
    }
    return !(
      invalidConditions.length === tempConditions.length &&
      (invalidConditions.length === tempChanges.length || isHoliday)
    );
  };

  const validateCondition = (change, condition, invalidConditions) => {
    switch (change.op) {
      case "time":
        return validateTimeCondition(change, condition, invalidConditions);
      case "date":
        return validateDateCondition(change, condition, invalidConditions);
      case "day":
        return validateDayCondition(change, condition, invalidConditions);
      case "holiday":
        return validateHolidayCondition(change, condition, invalidConditions);
      case "schedule":
        return validateScheduleCondition(change, condition, invalidConditions);
      default:
        return true;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{
        style: {
          width: "900px",
          position: "absolute",
          top: 30,
          height: "auto",
          overflow: "auto",
        },
      }}>
      <Box>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable">
              {transitions?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={index}
                    label={transitions[index].label}
                    {...a11yProps(index)}
                    sx={getTabStyles(index)}
                  />
                );
              })}
              <Tab
                key={transitions.length}
                value={transitions.length}
                icon={<AddIcon />}></Tab>
            </TabList>
          </Box>
          {transitions?.map((item, index) => {
            return (
              <TabPanel key={index} value={index} sx={{ padding: 0 }}>
                <div
                  style={{ display: index === selectedTab ? "block" : "none" }}>
                  <EdgeModal
                    editTransition={editTransition}
                    editEdge={editEdge}
                    intents={intents}
                    edges={edges}
                    pages={pages}
                    userPermissions={userPermissions}
                    selectedTab={selectedTab}
                    onTransitionUpdate={onTransitionUpdate}
                    updateModal={updateModal}
                    setUpdateModal={setUpdateModal}
                    index={index}
                    conflictTransitions={conflictTransitions}
                  />
                </div>
              </TabPanel>
            );
          })}
        </TabContext>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
            <span>
              <Button
                id="edge-modal-delete"
                variant="contained"
                disabled={!userPermissions}
                onClick={() => handleDeleteTransition(selectedTab)}>
                {t("flowgraph:delete")}
              </Button>
            </span>
          </Tooltip>

          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
            <span>
              <Button
                id="edge-modal-save"
                variant="contained"
                disabled={
                  validTransitions.some((valid) => !valid) ||
                  !userPermissions ||
                  conflictTransitions.some((valid) => !valid)
                }
                onClick={onSaveEdge}>
                {t("flowgraph:save")}
              </Button>
            </span>
          </Tooltip>
          <Button
            id="edge-modal-close"
            variant="contained"
            onClick={handleClose}>
            {t("flowgraph:close")}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}

EdgeModalTabsPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editEdgeProps: PropTypes.object.isRequired,
  previous: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  intents: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  userPermissions: PropTypes.bool.isRequired,
  propsSelectedTab: PropTypes.number,
  onDeleteTab: PropTypes.func.isRequired,
};
