import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { useSnackbar } from "notistack";
import { post } from "../shared/http/httpService";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/system";

const ClientImportModal = ({ open, onClose, client, refreshClients }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme(); 
  const [replaceEndpoint, setReplaceEndpoint] = useState(false);
  const [replaceCampaign, setReplaceCampaign] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const importDatabase = async () => {
    if (!file) {
      enqueueSnackbar(t("clientImport:selectFileWarning"), { variant: "warning" });
      return;
    }

    if (!client) {
      enqueueSnackbar(t("clientImport:invalidClient"), { variant: "error" });
      return;
    }

    let entorno = "";
    if (window.location.href.includes("develop")) {
      entorno = "develop";
    } else if (window.location.href.includes("testing")) {
      entorno = "testing";
    } else if (!window.location.href.includes("localhost")) {
      entorno = "prod";
    } else {
      entorno = "develop";
    }

    let formData = new FormData();
    formData.append("archivo", file, file.name);
    formData.append("replaceEndpoint", replaceEndpoint);
    formData.append("replaceCampaign", replaceCampaign);


    await axios
      .post(
        `${process.env.REACT_APP_API_GW}/file-download/import/${client}/${entorno}`,
        formData
      )
      .then(() => {
        post("/clients/trainAgent", { clientname: client })
          .then(() => {
            enqueueSnackbar(t("clientImport:importedClient"), {
              variant: "success",
            });
            refreshClients();
            handleClose();
          })
          .catch(() => {
            enqueueSnackbar(t("clientImport:errorImportingClient"), {
              variant: "error",
            });
          });
      })
      .catch(() => {
        enqueueSnackbar(t("clientImport:errorImportingClient"), {
          variant: "error",
        });
      })
      .finally(() => {
        setFile(null);
      });
  };

  const handleClose = () => {
    setReplaceEndpoint(false);
    setReplaceCampaign(false);
    onClose();  
    setFile(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t("clientImport:importDatabase", { client: client })}
      </DialogTitle>
      <DialogContent>
        <div
          {...getRootProps()}
          style={{
            padding: "20px",
            border: `2px dashed ${theme.palette.divider}`,
            borderRadius: "8px",
            marginBottom: "20px",
            textAlign: "center",
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            cursor: "pointer",
          }}
        >
          <input
            {...getInputProps()}
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {file ? file.name : t("clientImport:dragAndDropOrClick")}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={replaceEndpoint}
              onChange={(e) => setReplaceEndpoint(e.target.checked)}
              color="primary"
            />
          }
          label={t("clientImport:replaceEndpoint")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={replaceCampaign}
              onChange={(e) => setReplaceCampaign(e.target.checked)}
              color="primary"
            />
          }
          label={t("clientImport:replaceCampaign")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t("clientImport:cancel")}
        </Button>
        <Button onClick={importDatabase} variant="contained" color="primary" disabled={!file} >
          {t("clientImport:import")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientImportModal;
