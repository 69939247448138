import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const EndpointChanges = ({ endpoint = {}, originalEndpoint = {} }) => {
  const { t } = useTranslation();

  const compareValues = (key) => {
    const value1 = endpoint[key];
    const value2 = originalEndpoint[key];

    if (typeof value1 === "object" && typeof value2 === "object") {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  const renderEndpointField = (fieldName, displayName) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ marginLeft: 4, fontSize: "14px" }}>
        {displayName || fieldName}:
      </Typography>
      {compareValues(fieldName) ? (
        <>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.delete",
              textDecoration: "line-through",
            }}>
            {originalEndpoint[fieldName] || "N/A"}
          </Typography>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.new",
            }}>
            {endpoint[fieldName] || "N/A"}
          </Typography>
        </>
      ) : (
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {endpoint[fieldName] || "N/A"}
        </Typography>
      )}
    </div>
  );

  return (
    <div style={{ marginLeft: 2 }}>
      {renderEndpointField("hostName", t("host"))}
      {renderEndpointField("routeName", t("route"))}
    </div>
  );
};

EndpointChanges.propTypes = {
  endpoint: PropTypes.shape({
    hostName: PropTypes.string,
    routeName: PropTypes.string,
  }),
  originalEndpoint: PropTypes.shape({
    hostName: PropTypes.string,
    routeName: PropTypes.string,
  }),
};

export default EndpointChanges;
