import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TransferAgentChanges = ({
  transferAgent = {},
  originalTransferAgent = {},
  transferAgentPages = [],
}) => {
  const { t } = useTranslation();

  const getPageName = (pageId) => {
    if (!pageId) return "N/A";
    const page = transferAgentPages.find((p) => p._id === pageId);
    return page ? page.name : "N/A";
  };

  const shouldShowChange = (key) => {
    if (!transferAgent || !originalTransferAgent) return false;

    if (key === "agent") {
      const originalValue = originalTransferAgent.agent || "N/A";
      const newValue = transferAgent.agent || "N/A";
      return originalValue !== newValue;
    }

    if (key === "agent_page_id") {
      const originalValue = getPageName(originalTransferAgent.agent_page_id);
      const newValue = getPageName(transferAgent.agent_page_id);
      return originalValue !== newValue;
    }

    return false;
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {t("agent")}:
        </Typography>
        {shouldShowChange("agent") ? (
          <>
            <Typography
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.delete",
                textDecoration: "line-through",
              }}>
              {originalTransferAgent.agent || "N/A"}
            </Typography>
            <Typography
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.new",
              }}>
              {transferAgent.agent || "N/A"}
            </Typography>
          </>
        ) : (
          <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
            {transferAgent.agent || "N/A"}
          </Typography>
        )}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {t("page")}:
        </Typography>
        {shouldShowChange("agent_page_id") ? (
          <>
            <Typography
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.delete",
                textDecoration: "line-through",
              }}>
              {getPageName(originalTransferAgent.agent_page_id)}
            </Typography>
            <Typography
              sx={{
                marginLeft: 2,
                fontSize: "14px",
                color: "tracesName.new",
              }}>
              {getPageName(transferAgent.agent_page_id)}
            </Typography>
          </>
        ) : (
          <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
            {getPageName(transferAgent.agent_page_id)}
          </Typography>
        )}
      </div>
    </div>
  );
};

TransferAgentChanges.propTypes = {
  transferAgent: PropTypes.shape({
    agent: PropTypes.string,
    agent_page_id: PropTypes.string,
  }),
  originalTransferAgent: PropTypes.shape({
    agent: PropTypes.string,
    agent_page_id: PropTypes.string,
  }),
  transferAgentPages: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default TransferAgentChanges;
